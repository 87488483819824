import { useState, ReactNode } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useAuthenticatedAxios from "../../../../hooks/useAuthenticatedAxios";
import { object, string } from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import Modal from "../../../molecules/Modal";
import Button from "../../../molecules/Button";
import FormFieldContainer from "../../../atoms/FormFieldContainer";
import FormField from "../../../molecules/FormField";

type WarTeamCreate = {
	name: string;
};

type Props = {
	children: ReactNode;
};

export default function CreateWarTeamModal({ children }: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const axios = useAuthenticatedAxios();
	const queryClient = useQueryClient();

	const WarTeamSchema = object().shape({
		name: string().required("O nome é obrigatório"),
	});

	async function handleSubmit(
		values: WarTeamCreate,
		helpers: FormikHelpers<WarTeamCreate>
	) {
		try {
			await axios.post("/war/teams", values);

			queryClient.invalidateQueries();
		} catch (error) {
			console.error("An error occurred");
		}

		helpers.setSubmitting(false);
		setIsOpen(false);
	}

	return (
		<>
			<div onClick={() => setIsOpen(true)}>
				{children}
			</div>
			<Modal open={isOpen} onClose={() => setIsOpen(false)} title="Criar Equipe de Guerra">
				<Formik
					initialValues={{ name: "" }}
					onSubmit={handleSubmit}
					validationSchema={WarTeamSchema}
					validateOnBlur
					validateOnChange
				>
					{({ isSubmitting, errors, touched, isValid, dirty }) => (
						<Form>
							<div className="mb-10 flex items-center flex-col gap-4">
								<FormFieldContainer>
									<FormField
										name="name"
										label="Nome da Equipe"
										type="text"
										errors={errors}
										touched={touched}
										placeholder="Maicon Lanches"
									/>
								</FormFieldContainer>
								<Button
									loading={isSubmitting}
									className="w-full"
									disabled={!dirty || !isValid}
									type="submit"
								>
									Salvar
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
}
