import { WarCharacter } from '@/types/WarCharacter';
import HeadingText from '../atoms/HeadingText';
import { cn, debounce } from '@/libs/utils';
import { useState } from 'react';
import { ChevronsUp, Crown, Shield, Sword, Swords } from 'lucide-react';
import Button from '../molecules/Button';
import useAuthenticatedAxios from '@/hooks/useAuthenticatedAxios';
import Spinner from '../atoms/Spinner';
import AddCharacterToWarTeamModal from './modal/war/AddCharacterToWarTeamModal';
import { useQueryClient } from '@tanstack/react-query';
import {
  StatDisplay,
  BonusesList,
  AtributesDisplay,
  SkillDisplay,
} from '../molecules/War';

type InputRangeProps = {
  label: string;
  value: number;
  maxValue: number;
  onChange: (value: number) => void;
  color: 'red' | 'amber' | 'emerald';
  disabled?: boolean;
  isLoading?: boolean;
};

function InputRange({
  label,
  value,
  maxValue,
  onChange,
  color,
  disabled = false,
  isLoading,
}: InputRangeProps) {
  const [rangeValue, setRangeValue] = useState(value);

  function onUpdateRangeValue(value: number) {
    setRangeValue(value);
    onChange(value);
  }

  const colorClasses = {
    red: 'text-red-500 [&::-webkit-slider-thumb]:bg-red-600 [&::-webkit-slider-thumb]:shadow-[-100vw_0_0_100vw_#f87171]',
    amber:
      'text-amber-500 [&::-webkit-slider-thumb]:bg-amber-600 [&::-webkit-slider-thumb]:shadow-[-100vw_0_0_100vw_#fbbf24]',
    emerald:
      'text-emerald-500 [&::-webkit-slider-thumb]:bg-emerald-600 [&::-webkit-slider-thumb]:shadow-[-100vw_0_0_100vw_#30cf9a]',
  };

  return (
    <div className="flex flex-col gap-2 w-full">
      <label className={`text-slate-300 text-sm ${colorClasses[color]}`}>
        {label}{' '}
        <span>
          ({rangeValue}/{maxValue})
        </span>{' '}
      </label>
      <input
        disabled={disabled}
        type="range"
        min="0"
        max={maxValue}
        value={rangeValue}
        onChange={(e) => onUpdateRangeValue(Number(e.target.value))}
        className={cn(
          'appearance-none transition-opacity bg-transparent rounded-md [&::-webkit-slider-runnable-track]:bg-slate-700 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-[20px] [&::-webkit-slider-thumb]:w-[10px] overflow-hidden',
          colorClasses[color],
          disabled && 'opacity-50'
        )}
      />
    </div>
  );
}

export type WarCharacterCardProps = {
  character: WarCharacter;
  editable?: boolean;
  onRemove?: () => void;
  onInsert?: () => void;
  onMarkAsLeader?: () => void;
  isLoadingActions?: boolean;
  isOnTeamPage?: boolean;
  disabledActions?: boolean;
  isTeamLeader?: boolean;
  hideSkills?: boolean;
};

export default function WarCharacterCard({
  character,
  editable = true,
  onRemove,
  onInsert,
  isLoadingActions,
  isOnTeamPage,
  disabledActions,
  isTeamLeader,
  onMarkAsLeader,
  hideSkills,
}: WarCharacterCardProps) {
  const axios = useAuthenticatedAxios();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);

  const handleLifePointsChange = debounce(
    async (characterId: string, value: number) => {
      setIsLoading(true);
      await axios.put(`/war/characters/${characterId}`, {
        lifePoints: value,
        status: value === 0 ? 'dead' : 'alive',
      });
      queryClient.invalidateQueries({ queryKey: ['war'] });
      setIsLoading(false);
    },
    1000
  );

  const handleEnergyPointsChange = debounce(
    async (characterId: string, value: number) => {
      setIsLoading(true);
      await axios.put(`/war/characters/${characterId}`, {
        energyPoints: value,
      });
      queryClient.invalidateQueries({ queryKey: ['war'] });
      setIsLoading(false);
    },
    1000
  );

  return (
    <div
      className={cn(
        'border-slate-700 bg-slate-900 border rounded-lg p-2',
        character.type === 'leader' && 'col-span-2 p-4'
      )}
    >
      <header className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <img
            src={character.imageUrl}
            alt={character.name}
            className={cn(
              'bg-slate-700 object-contain',
              character.status === 'dead' && 'opacity-75 grayscale',
              character.type === 'leader' && 'w-36 h-36 rounded-lg',
              character.type === 'member' && 'w-20 h-20 rounded-md'
            )}
          />
          <div className="flex flex-col gap-3 relative">
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-1">
                {isTeamLeader && <Crown className="text-indigo-500 w-4 h-4" />}
                <HeadingText
                  type="h3"
                  color="slate"
                  weight="medium"
                  className={cn(isTeamLeader && 'text-indigo-400')}
                >
                  {character.name}
                </HeadingText>
              </div>
              {character.type === 'leader' && (
                <StatDisplay
                  text="Defesa"
                  number={character.defense ?? 0}
                  Icon={Shield}
                />
              )}
              {character.type === 'leader' && (
                <StatDisplay
                  text="Iniciativa"
                  number={character.initiative ?? 0}
                  Icon={ChevronsUp}
                />
              )}
              {isLoading && <Spinner color="white" size="small" />}
            </div>
            {character.bonuses && (
              <BonusesList
                bonuses={character.bonuses}
                onlyOneList={character.type === 'member'}
              />
            )}
            {character.atributes && (
              <AtributesDisplay atributes={character.atributes} />
            )}
          </div>
        </div>
        <div className="flex items-end flex-col gap-4">
          <InputRange
            label="Pontos de Vida"
            value={character.lifePoints}
            maxValue={character.maxLifePoints}
            onChange={(value) => handleLifePointsChange(character.id, value)}
            color="emerald"
            disabled={!editable || isLoading}
            isLoading={isLoading}
          />
          {character.type === 'leader' && (
            <InputRange
              label="Pontos de Esforço"
              value={character.energyPoints ?? 0}
              maxValue={character.maxEnergyPoints ?? 0}
              onChange={(value) =>
                handleEnergyPointsChange(character.id, value)
              }
              color="amber"
              disabled={!editable || isLoading}
              isLoading={isLoading}
            />
          )}
          {character.type === 'leader' && (
            <div className="flex items-center gap-4">
              <StatDisplay
                text="Dano Médio"
                number={character.averageDamage ?? 0}
                Icon={Sword}
              />
              <StatDisplay
                text="Dano Máximo"
                number={character.maxDamage ?? 0}
                Icon={Swords}
              />
            </div>
          )}
        </div>
      </header>
      {!hideSkills && (
        <>
          <div className="flex flex-col gap-4">
            {character.skills?.map((skill) => (
              <SkillDisplay
                key={skill.name.toLowerCase().replace(' ', '-')}
                skill={skill}
              />
            ))}
          </div>
          <footer className="mt-4 flex justify-end gap-4">
            {isOnTeamPage && (
              <>
                <Button
                  disabled={disabledActions}
                  loading={isLoadingActions}
                  onClick={onMarkAsLeader}
                  color="secondary"
                  size="small"
                >
                  Marcar como lider
                </Button>
                <Button
                  disabled={disabledActions}
                  loading={isLoadingActions}
                  onClick={onRemove}
                  color="destructive"
                  size="small"
                >
                  Remover da equipe
                </Button>
              </>
            )}
            {!isOnTeamPage && (
              <AddCharacterToWarTeamModal characterId={character.id}>
                <Button
                  disabled={disabledActions}
                  loading={isLoadingActions}
                  onClick={onInsert}
                  color="default"
                  size="small"
                >
                  Adicionar à uma equipe
                </Button>
              </AddCharacterToWarTeamModal>
            )}
          </footer>
        </>
      )}
    </div>
  );
}
