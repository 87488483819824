import { useQuery } from "@tanstack/react-query";
import useAuthenticatedAxios from "../useAuthenticatedAxios";
import { WarCharacter } from '@/types/WarCharacter';

export default function useWarCharacters() {
	const axios = useAuthenticatedAxios();

	function queryFn() {
		return axios.get("/war/characters");
	}

	const { data, isError, isLoading } = useQuery({
		queryKey: ["war", "characters"],
		queryFn,
	});

	return {
		warCharacters: (data?.data as WarCharacter[]) || [],
		isError,
		isLoadingWarCharacters: isLoading,
	};
}
