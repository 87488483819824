import { Hexagon } from 'lucide-react';

import { WarCharacterSkill } from '@/types/WarCharacter';
import { titleCase } from '@/libs/utils';
import BodyText from '../atoms/BodyText';
import { WarCharacterAtributes } from '@/types/WarCharacter';

export function BonusesList({ bonuses, onlyOneList = false }: { bonuses: Record<string, number>, onlyOneList?: boolean }) {
	const filteredBonuses = Object.entries(bonuses).filter(([_, value]) => value > 0);

	return (
		<div className='flex gap-4'>
			{window.innerWidth < 1300 || onlyOneList ? (
				<ul>
					{filteredBonuses.map(([bonus, value]) => (
						<li key={bonus} className='text-sm text-slate-400'>
							<span className='text-indigo-400'>+{value}</span> em <span className='text-slate-300'>{titleCase(bonus)}</span>
						</li>
					))}
				</ul>
			) : (
				<>
					<ul>
						{filteredBonuses.slice(0, Math.ceil(filteredBonuses.length / 2)).map(([bonus, value]) => (
							<li key={bonus} className='text-sm text-slate-400'>
								<span className='text-indigo-400'>+{value}</span> em <span className='text-slate-300'>{titleCase(bonus)}</span>
							</li>
						))}
					</ul>
					<ul>
						{filteredBonuses.slice(Math.ceil(filteredBonuses.length / 2)).map(([bonus, value]) => (
							<li key={bonus} className='text-sm text-slate-400'>
								<span className='text-indigo-400'>+{value}</span> em <span className='text-slate-300'>{titleCase(bonus)}</span>
							</li>
						))}
					</ul>
				</>
			)}
		</div>
	);
}

type StatDisplayProps = {
	text: string;
	number: number;
	Icon: React.FC<{ className?: string }>;
};

export function StatDisplay({ text, number, Icon }: StatDisplayProps) {
	return (
		<BodyText className='flex items-center gap-1'>
			<Icon className='h-4 w-4 text-indigo-400' />
			{number}
			<span className='text-slate-400 text-sm'>{text}</span>
		</BodyText>
	);
}

type AtributeDisplayProps = {
	atribute: string;
	value: number;
};

function AtributeDisplay({ atribute, value }: AtributeDisplayProps) {
	return (
		<div className='flex flex-col gap-1 items-center'>
			<div className='w-8 h-8 flex items-center justify-center relative'>
				<span className='text-indigo-300'>{value}</span>
				<Hexagon className='absolute text-indigo-600 h-9 w-9' />
			</div>
			<span className='text-slate-300 text-sm'>{atribute}</span>
		</div>
	);
}

type SkillDisplayProps = {
	skill: WarCharacterSkill;
	imageUrl?: string;
};

export function SkillDisplay({ skill, imageUrl }: SkillDisplayProps) {
	if (imageUrl) {
		return (
			<div className='flex gap-2 items-center'>
				<div className='min-w-[48px] w-12 min-h-[48px] h-12 rounded-full flex items-center justify-center bg-slate-800'>
					<img src={imageUrl} alt={skill.name} className='w-12 h-12 rounded-full object-contain' />
				</div>
				<div className='flex flex-col gap-1'>
					<div className='flex gap-2 items-center'>
						{skill.energyPointsCost > 0 && <StatDisplay text='PE' number={skill.energyPointsCost} Icon={Hexagon} />}
						<span className='text-slate-100'>{skill.name}</span>
					</div>
					<span className='text-slate-400 text-sm'>{skill.description}</span>
				</div>
			</div>
		)
	}

	return (
		<div className='flex flex-col gap-1'>
			<div className='flex gap-2 items-center'>
				{skill.energyPointsCost > 0 && <StatDisplay text='PE' number={skill.energyPointsCost} Icon={Hexagon} />}
				<span className='text-slate-100'>{skill.name}</span>
			</div>
			<span className='text-slate-400 text-sm'>{skill.description}</span>
		</div>
	);
}


export function AtributesDisplay({ atributes }: { atributes: WarCharacterAtributes }) {
	return (
		<div className='flex w-full gap-5'>
			<AtributeDisplay atribute='AGI' value={atributes.agility} />
			<AtributeDisplay atribute='INT' value={atributes.intelligence} />
			<AtributeDisplay atribute='FOR' value={atributes.strength} />
			<AtributeDisplay atribute='PRE' value={atributes.presence} />
			<AtributeDisplay atribute='VIG' value={atributes.vigor} />
		</div>
	);
}