import { XCircleIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";

type Props = {
  label: string;
  name?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  disabled?: boolean;
  error?: any;
  helperText?: string;
  placeholder?: string;
  rows?: number;
  cols?: number;
};

export default function TextArea({
  name,
  onChange,
  onBlur,
  disabled,
  error,
  value,
  helperText,
  placeholder,
  label,
  rows = 4,
  cols,
}: Props) {
  return (
    <div className="relative flex flex-col gap-2">
      <label
        className="absolute left-3 top-1 text-xs text-slate-300"
        htmlFor={name}
      >
        {label}
      </label>
      <textarea
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        rows={rows}
        cols={cols}
        className={twMerge(
          "rounded-lg border-[1px] border-slate-800 bg-slate-900 px-3 pt-5 text-slate-200 outline-none transition-all focus:border-slate-700 focus:text-slate-100 text-sm",
          error && "border-red-500 text-red-600 focus:border-red-700"
        )}
      />
      {helperText && !error && (
        <span className="cursor-default text-xs text-slate-600">
          {helperText}
        </span>
      )}
      {error && (
        <div className="flex items-center gap-2">
          <XCircleIcon className="h-5 w-5 text-red-500" />
          <span className="cursor-default text-xs text-red-500">{error}</span>
        </div>
      )}
    </div>
  );
}
