import { useField } from "formik";
import Input from "./Input";
import { HTMLInputTypeAttribute } from "react";

type Props = {
  label: string;
  type: HTMLInputTypeAttribute;
  name: string;
  helperText?: string;
  errors?: any;
  touched?: any;
  placeholder?: string;
};

export default function FormField({
  touched,
  label,
  helperText,
  errors,
  type,
  name,
  placeholder,
}: Props) {
  const [field] = useField({ name });

  const hasTouched = touched && touched[name];
  const hasErrors = errors && errors[name] && hasTouched;

  return (
    <Input
      helperText={helperText}
      label={label}
      type={type}
      {...field}
      error={hasErrors ? errors[name] : ""}
      placeholder={placeholder}
    />
  );
}
