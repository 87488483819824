import HeadingText from '@/components/atoms/HeadingText';
import FullScreenSection from '../components/atoms/FullScreenSection';
import useAuthenticatedRoute from '../hooks/useAuthenticatedRoute';
import Header from '@/components/organisms/Header';
import Button from '@/components/molecules/Button';
import CreateWarTeamModal from '@/components/organisms/modal/war/CreateWarTeamModal';
import useWarTeams from '@/hooks/api/useWarTeams';
import Spinner from '@/components/atoms/Spinner';
import ContentCard from '@/components/atoms/ContentCard';
import WarCharacterCard from '@/components/organisms/WarCharacterCard';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import useAuthenticatedAxios from '@/hooks/useAuthenticatedAxios';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import WarTeamResume from '@/components/organisms/WarTeamResume';

export default function HomePage() {
  useAuthenticatedRoute();

  const { warTeams, isLoadingWarTeams } = useWarTeams();

  const queryClient = useQueryClient();
  const axios = useAuthenticatedAxios();
  const [isRemovingCharacter, setIsRemovingCharacter] = useState(false);
  const [isMarkingLeader, setIsMarkingLeader] = useState(false);

  async function handleRemoveCharacterFromTeam(
    warTeamId: number,
    characterId: number
  ) {
    const confirmation = window.confirm(
      'Tem certeza que deseja remover este personagem da equipe?'
    );
    if (!confirmation) return;

    setIsRemovingCharacter(true);
    try {
      await axios.delete(`/war/teams/${warTeamId}/characters`, {
        data: { characterId },
      });
      queryClient.invalidateQueries({ queryKey: ['war', 'teams'] });
    } catch (error) {
      console.error('Failed to remove character from team', error);
    } finally {
      setIsRemovingCharacter(false);
    }
  }

  async function handleMarkAsLeader(warTeamId: number, characterId: number) {
    setIsMarkingLeader(true);
    try {
      await axios.put(`/war/teams/${warTeamId}`, {
        mainCharacterId: characterId,
      });
      queryClient.invalidateQueries({ queryKey: ['war', 'teams'] });
    } catch (error) {
      console.error('Failed to mark character as leader', error);
    } finally {
      setIsMarkingLeader(false);
    }
  }

  return (
    <>
      <FullScreenSection className="flex flex-col px-12 py-8 gap-8">
        <Header currentTab="war" />
        <div className="flex justify-between">
          <HeadingText color="white" weight="semibold">
            Suas equipes de guerra
          </HeadingText>
          <CreateWarTeamModal>
            <Button>Nova equipe</Button>
          </CreateWarTeamModal>
        </div>
        {isLoadingWarTeams && (
          <Spinner size="large" color="indigo" className="m-auto" />
        )}
        {!isLoadingWarTeams && (
          <main className="grid grid-cols-1 xl:grid-cols-2 gap-8">
            {warTeams
              .sort((a, b) => a.name?.localeCompare(b?.name || '') || 0)
              .map((warTeam) => (
                <Tabs key={warTeam.id} defaultValue="personagens">
                  <ContentCard>
                    <header className="flex justify-between items-center">
                      <HeadingText type="h3" color="slate" weight="semibold">
                        {warTeam.name}
                      </HeadingText>
                      <TabsList>
                        <TabsTrigger value="personagens">
                          Personagens
                        </TabsTrigger>
                        <TabsTrigger value="resumo">Resumo</TabsTrigger>
                      </TabsList>
                    </header>
                    <TabsContent value="personagens">
                      <div className="mt-4 grid grid-cols-2 gap-4">
                        {warTeam.characters
                          .sort(
                            (a, b) => a.name?.localeCompare(b?.name || '') || 0
                          )
                          .sort((a) => (a.type === 'leader' ? -1 : 1))
                          .sort((a) =>
                            a.id === warTeam.mainCharacterId ? -1 : 1
                          )
                          .map((character) => (
                            <WarCharacterCard
                              isLoadingActions={
                                isRemovingCharacter || isMarkingLeader
                              }
                              key={character.id}
                              character={character}
                              onRemove={() =>
                                handleRemoveCharacterFromTeam(
                                  warTeam.id,
                                  character.id
                                )
                              }
                              onMarkAsLeader={() =>
                                handleMarkAsLeader(warTeam.id, character.id)
                              }
                              isTeamLeader={
                                character.id === warTeam.mainCharacterId
                              }
                              isOnTeamPage
                            />
                          ))}
                      </div>
                    </TabsContent>
                    <TabsContent value="resumo">
                      <WarTeamResume warTeam={warTeam} />
                    </TabsContent>
                  </ContentCard>
                </Tabs>
              ))}
          </main>
        )}
      </FullScreenSection>
    </>
  );
}
