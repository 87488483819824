import React from 'react';
import HeadingText from '@/components/atoms/HeadingText';
import FullScreenSection from '@/components/atoms/FullScreenSection';
import Header from '@/components/organisms/Header';
import Spinner from '@/components/atoms/Spinner';
import WarCharacterCard from '@/components/organisms/WarCharacterCard';
import useWarCharacters from '@/hooks/api/useWarCharacters';

export default function DeadPanelPage() {
  const { warCharacters, isLoadingWarCharacters } = useWarCharacters();

  const deadCharacters = warCharacters.filter(
    (character) => character.status === 'dead'
  );

  return (
    <FullScreenSection className="flex flex-col px-12 py-8 gap-8">
      <Header currentTab="dead-characters" />
      <HeadingText color="white" weight="semibold">
        Cemitério (Central do F no chat)
      </HeadingText>
      {isLoadingWarCharacters && (
        <Spinner size="large" color="indigo" className="m-auto" />
      )}
      {!isLoadingWarCharacters && (
        <main className="grid grid-cols-2 xl:grid-cols-4 gap-8">
          {deadCharacters.map((character) => (
            <WarCharacterCard
              key={character.id}
              character={character}
              editable={false}
              disabledActions={true}
              hideSkills={true}
            />
          ))}
        </main>
      )}
    </FullScreenSection>
  );
}
