import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useAuthenticatedAxios from "../../../../hooks/useAuthenticatedAxios";
import Modal from "../../../molecules/Modal";
import Button from "../../../molecules/Button";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import FormFieldContainer from "../../../atoms/FormFieldContainer";
import useWarTeams from "../../../../hooks/api/useWarTeams";
import { Select, SelectItem, SelectTrigger, SelectContent, SelectGroup, SelectLabel, SelectValue } from '@/components/ui/select';
import { Users } from 'lucide-react';

type AddCharacterToWarTeamModalProps = {
	characterId: number;
	children: React.ReactNode;
};

type AddCharacterToWarTeamFormValues = {
	teamId: number;
};

export default function AddCharacterToWarTeamModal({ characterId, children }: AddCharacterToWarTeamModalProps) {
	const [isOpen, setIsOpen] = useState(false);

	const axios = useAuthenticatedAxios();
	const queryClient = useQueryClient();
	const { warTeams } = useWarTeams();

	async function handleSubmit(
		values: AddCharacterToWarTeamFormValues,
		helpers: FormikHelpers<AddCharacterToWarTeamFormValues>
	) {
		try {
			await axios.post(`/war/teams/${values.teamId}/characters`, { characterId });

			queryClient.invalidateQueries({ queryKey: ['war'] });
		} catch (error) {
			console.error("An error occurred");
		}

		helpers.setSubmitting(false);
		setIsOpen(false);
	}

	return (
		<>
			<div onClick={() => setIsOpen(true)}>
				{children}
			</div>
			<Modal open={isOpen} onClose={() => setIsOpen(false)} title="Adicionar Personagem">
				<Formik
					initialValues={{ teamId: warTeams[0]?.id || 0 }}
					onSubmit={handleSubmit}
					validateOnBlur
					validateOnChange
				>
					{({ isSubmitting, isValid, dirty }) => (
						<Form>
							<div className="mb-10 flex items-center flex-col gap-4">
								<FormFieldContainer>
									<Field name="teamId">
										{({ field, form }: FieldProps) => (
											<Select
												value={field.value.toString()}
												onValueChange={(value) => form.setFieldValue(field.name, parseInt(value))}
											>
												<SelectTrigger className="w-[250px]">
													<SelectValue placeholder="Selecione a Equipe" />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														<SelectLabel>Equipes</SelectLabel>
														{warTeams.map(team => (
															<SelectItem key={team.id} value={team.id.toString()}>
																{team.name}
															</SelectItem>
														))}
													</SelectGroup>
												</SelectContent>
											</Select>
										)}
									</Field>
								</FormFieldContainer>
								<Button
									loading={isSubmitting}
									className="w-full"
									disabled={!isValid}
									type="submit"
								>
									Adicionar
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
}
