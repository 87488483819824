import * as Dialog from "@radix-ui/react-dialog";
import { twMerge } from "tailwind-merge";
import { ReactNode } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import IconButton from "../atoms/IconButton";
import HeadingText from "../atoms/HeadingText";

interface ModalProps {
  open: boolean;
  title: string;
  onClose?: () => void;
  children?: ReactNode;
  className?: string;
}

export default function Modal({ open, children, title, onClose, className }: ModalProps) {
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay
          onClick={onClose}
          className="fixed inset-0 z-40 bg-black/50 backdrop-blur-sm data-[state=closed]:animate-fadeOut data-[state=open]:animate-fadeIn"
        />

        <Dialog.Content
          className={twMerge(
            "fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50",
            "p-6 space-y-10 bg-gray-900 rounded-2xl shadow-menu w-full max-w-[400px] outline-none",
            "data-[state=open]:animate-contentShow data-[state=closed]:animate-contentHide",
            className
          )}
        >
          <header className="flex h-12 justify-center items-center relative">
            <IconButton
              color="slate"
              className="absolute left-0"
              onClick={onClose}
            >
              <XMarkIcon className="h-6 w-6" />
            </IconButton>
            <HeadingText type="h4">{title}</HeadingText>
          </header>

          <main>{children}</main>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
