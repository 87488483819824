import React from 'react';
import HeadingText from '@/components/atoms/HeadingText';
import {
  AtributesDisplay,
  BonusesList,
  SkillDisplay,
} from '@/components/molecules/War';
import GenerateAIInsightsModal from '@/components/organisms/modal/war/GenerateAIInsightsModal';
import Button from '@/components/molecules/Button';
import { WarCharacterAtributes } from '@/types/WarCharacter';
import { WarTeam } from '@/types/WarTeam';

interface WarTeamResumeProps {
  warTeam: WarTeam;
}

const WarTeamResume: React.FC<WarTeamResumeProps> = ({ warTeam }) => {
  return (
    <>
      <div className="mb-4">
        <HeadingText type="h4" color="slate" weight="medium" className="mb-2">
          Atributos dos líderes somados
        </HeadingText>
        <AtributesDisplay
          atributes={warTeam.characters.reduce(
            (acc, character) => {
              if (character.type === 'leader') {
                acc.agility += character.atributes?.agility || 0;
                acc.intelligence += character.atributes?.intelligence || 0;
                acc.strength += character.atributes?.strength || 0;
                acc.presence += character.atributes?.presence || 0;
                acc.vigor += character.atributes?.vigor || 0;
              }
              return acc;
            },
            {
              agility: 0,
              intelligence: 0,
              strength: 0,
              presence: 0,
              vigor: 0,
            } as WarCharacterAtributes
          )}
        />
      </div>
      <HeadingText type="h4" color="slate" weight="medium" className="mb-2">
        Bônus Disponíveis
      </HeadingText>
      {warTeam.characters.length > 0 && (
        <BonusesList
          bonuses={warTeam.characters.reduce((acc, character) => {
            if (character.bonuses && character.status === 'alive') {
              Object.entries(character.bonuses).forEach(([bonus, value]) => {
                acc[bonus] = (acc[bonus] || 0) + value;
              });
            }
            return acc;
          }, {} as Record<string, number>)}
          onlyOneList
        />
      )}
      {warTeam.characters.length > 0 && (
        <div className="mt-4">
          <HeadingText type="h4" color="slate" weight="medium" className="mb-2">
            Habilidades Disponíveis
          </HeadingText>
          <div className="flex flex-col gap-4">
            {warTeam.characters
              .filter((character) => character.status === 'alive')
              .map((character) =>
                character.skills?.map((skill) => (
                  <SkillDisplay
                    imageUrl={character.imageUrl}
                    key={skill.name.toLowerCase().replace(' ', '-')}
                    skill={skill}
                  />
                ))
              )}
          </div>
        </div>
      )}
      <footer className="pt-4 flex justify-end">
        <GenerateAIInsightsModal warTeamId={warTeam.id}>
          <Button size="small">Gerar Insights com IA</Button>
        </GenerateAIInsightsModal>
      </footer>
    </>
  );
};

export default WarTeamResume;
