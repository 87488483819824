import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import LoginPage from '../pages/Login';
import HomePage from '../pages/Home';
import RegisterPage from '../pages/Register';
import InitiativePage from '../pages/Initiative';
import WarPanelPage from '../pages/WarPanel';
import WarCharactersListPage from '@/pages/WarCharactersList';
import DeadPanelPage from '@/pages/DeadPanel';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/register',
    element: <RegisterPage />,
  },
  {
    path: '/initiative/:id',
    element: <InitiativePage />,
  },
  {
    path: '/war',
    element: <WarPanelPage />,
  },
  {
    path: '/war-characters',
    element: <WarCharactersListPage />,
  },
  {
    path: '/dead-characters',
    element: <DeadPanelPage />,
  },
];

const router = createBrowserRouter(routes);

export default function Router() {
  return <RouterProvider router={router} />;
}
