import { useState } from "react";
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Link } from 'react-router-dom';
import useUserInfo from '@/hooks/api/useUserInfo';
import Logo from '../atoms/Logo';
import UserMenu from '../molecules/UserMenu';

type HeaderProps = {
	currentTab: 'initiative' | 'war' | 'war-characters' | 'dead-characters';
}

export default function Header({ currentTab }: HeaderProps) {
	const [isChangeUserLogoOpen, setChangeUserLogoOpen] = useState(false);

	const { isLoadingUser, userInfo } = useUserInfo();

	function handleOpenChangeUserModal() {
		setChangeUserLogoOpen(true);
	}

	return (
		<header className="flex justify-between">
			<nav className="flex items-center gap-4">
				<Logo size="small" />
				<Tabs defaultValue={currentTab}>
					<TabsList>
						<TabsTrigger value="initiative" asChild>
							<Link to="/">Iniciativa</Link>
						</TabsTrigger>
						<TabsTrigger value="war" asChild>
							<Link to="/war">Sistema de Guerra</Link>
						</TabsTrigger>
						<TabsTrigger value="war-characters" asChild>
							<Link to="/war-characters">Personagens de Guerra</Link>
						</TabsTrigger>
						<TabsTrigger value="dead-characters" asChild>
							<Link to="/dead-characters">Cemitério</Link>
						</TabsTrigger>
					</TabsList>
				</Tabs>
			</nav>
			<UserMenu
				userName={userInfo.name}
				userId={userInfo.id}
				onOpenChangeLogoModal={handleOpenChangeUserModal}
			/>
		</header>
	);
}