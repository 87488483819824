import HeadingText from '@/components/atoms/HeadingText';
import FullScreenSection from "../components/atoms/FullScreenSection";
import useAuthenticatedRoute from "../hooks/useAuthenticatedRoute";
import Header from '@/components/organisms/Header';
import Spinner from '@/components/atoms/Spinner';
import WarCharacterCard from '@/components/organisms/WarCharacterCard';
import useWarCharacters from '@/hooks/api/useWarCharacters';
import { useState } from 'react';
import Input from '@/components/molecules/Input';

export default function WarCharactersListPage() {
	useAuthenticatedRoute();

	const { warCharacters, isLoadingWarCharacters } = useWarCharacters();
	const [searchTerm, setSearchTerm] = useState('');

	const filteredCharacters = warCharacters.filter(character =>
		character.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<>
			<FullScreenSection className="flex flex-col px-12 py-8 gap-8">
				<Header currentTab="war-characters" />
				<div className="flex justify-between items-center">
					<HeadingText color="white" weight="semibold">
						Personagens de guerra
					</HeadingText>
					<Input
						label="Pesquisar por nome"
						type="text"
						placeholder="Jorge"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				{isLoadingWarCharacters && <Spinner size='large' color='indigo' className='m-auto' />}
				{!isLoadingWarCharacters && (<main className='grid grid-cols-2 xl:grid-cols-4 gap-8'>
					{filteredCharacters.sort((a) => a.type === 'leader' ? -1 : 1)
						.map((character) => (
							<WarCharacterCard key={character.id} character={character} disabledActions={character.hasTeam} editable={false} />
						))}
				</main>)}

			</FullScreenSection>
		</>
	);
}
