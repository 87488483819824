import { ReactNode } from "react";
import { styled } from "react-tailwind-variants";

type Props = {
  type?: "h1" | "h2" | "h3" | "h4";
  weight?: "medium" | "bold" | "semibold";
  color?: "slate" | "indigo" | "white";
  children: ReactNode;
  className?: string;
};

export default function HeadingText({ type, color, children, weight, className }: Props) {
  const StyledComponent = styled(type || "h2", {
    base: "tracking-tight",
    variants: {
      type: {
        h1: "text-[32px]",
        h2: "text-2xl",
        h3: "text-xl",
        h4: "text-lg",
      },
      color: {
        slate: "text-slate-200",
        white: "text-white",
        indigo: "text-indigo-800",
      },
      weight: {
        medium: "font-medium",
        semibold: "font-semibold",
        bold: "font-bold",
      },
    },
    defaultVariants: {
      type: "h2",
      color: "white",
      weight: "bold",
    },
  });

  return (
    <StyledComponent type={type} color={color} weight={weight} className={className}>
      {children}
    </StyledComponent>
  );
}
