import axios, { CreateAxiosDefaults } from 'axios';
import useAuthStore from '../stores/useAuthStore';
import env from '../libs/env';

export default function useAuthenticatedAxios() {
  const accessToken = useAuthStore((state) => state.accessToken);
  const logout = useAuthStore((state) => state.logout);

  const config: CreateAxiosDefaults = {
    baseURL: env.API_URL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const client = axios.create(config);

  client.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );

  return client;
}
