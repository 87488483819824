import { useQuery } from "@tanstack/react-query";
import useAuthenticatedAxios from "../useAuthenticatedAxios";
import { WarTeam } from '@/types/WarTeam';

export default function useWarTeams() {
  const axios = useAuthenticatedAxios();

  function queryFn() {
    return axios.get("/war/teams");
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ["war", "teams"],
    queryFn,
  });

  return {
    warTeams: (data?.data as WarTeam[]) || [],
    isError,
    isLoadingWarTeams: isLoading,
  };
}
