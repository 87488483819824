import { useState } from "react";
import FullScreenSection from "../components/atoms/FullScreenSection";
import CreatePresetModal from "../components/organisms/modal/CreatePresetModal";
import PresetsCard from "../components/organisms/PresetsCard";
import usePresets from "../hooks/api/usePresets";
import useUserInfo from "../hooks/api/useUserInfo";
import useAuthenticatedRoute from "../hooks/useAuthenticatedRoute";
import InitiativeCard from "../components/organisms/InitiativeCard";
import useInitiative from "../hooks/api/useInitiative";
import AddInitiativeModal from "../components/organisms/modal/AddInitiativeModal";
import ConfirmModal from "../components/organisms/modal/ConfirmModal";
import ChangeUserLogoModal from "../components/organisms/modal/ChangeUserLogoModal";
import Header from '@/components/organisms/Header';

export default function HomePage() {
  useAuthenticatedRoute();

  const [isCreatePresetOpen, setCreatePresetOpen] = useState(false);
  const [isChangeUserLogoOpen, setChangeUserLogoOpen] = useState(false);
  const [isAddInitiativeOpen, setAddInitiativeOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  const [initiativeData, setInitiativeData] = useState({
    name: "",
    imageUrl: "",
  });
  const [confirmModalData, setConfirmModalData] = useState({
    text: "",
    onSubmit: async () => { },
  });

  const { isLoadingUser, userInfo } = useUserInfo();
  const { presets } = usePresets();
  const { initiativeCharacters, isLoadingInitiative } = useInitiative();

  function handleOpenAddInitiativeModal(
    name: string = "",
    imageUrl: string = ""
  ) {
    setAddInitiativeOpen(true);
    setInitiativeData({ name, imageUrl });
  }

  function handleOpenConfirmModal(text: string, onSubmit: () => Promise<void>) {
    setConfirmModalData({ onSubmit, text });
    setConfirmModalOpen(true);
  }

  function handleCloseAddInitiativeModal() {
    setAddInitiativeOpen(false);
  }

  function handleCloseConfirmModal() {
    setConfirmModalOpen(false);
  }

  function handleOpenCreatePresetModal() {
    setCreatePresetOpen(true);
  }

  function handleCloseCreatePresetModal() {
    setCreatePresetOpen(false);
  }

  function handleCloseChangeUserModal() {
    setChangeUserLogoOpen(false);
  }

  return (
    <>
      <FullScreenSection className="flex justify-between flex-col px-12 py-8">
        <Header currentTab="initiative" />
        <PresetsCard
          presets={presets}
          onModalOpen={handleOpenCreatePresetModal}
          onConfirmModalOpen={handleOpenConfirmModal}
          onInitiativeModalOpen={handleOpenAddInitiativeModal}
        />
        {!isLoadingUser && !isLoadingInitiative && (
          <InitiativeCard
            isLoading={isLoadingUser || isLoadingInitiative}
            onConfirmModalOpen={handleOpenConfirmModal}
            isShowingInitiative={userInfo.isInitiativeVisible}
            initiative={initiativeCharacters}
            onModalOpen={handleOpenAddInitiativeModal}
            activeCharacter={userInfo.activeIndex}
          />
        )}
      </FullScreenSection>
      <ChangeUserLogoModal
        open={isChangeUserLogoOpen}
        onClose={handleCloseChangeUserModal}
      />
      <CreatePresetModal
        open={isCreatePresetOpen}
        onClose={handleCloseCreatePresetModal}
      />
      <AddInitiativeModal
        initiativeData={initiativeData}
        open={isAddInitiativeOpen}
        onClose={handleCloseAddInitiativeModal}
      />
      <ConfirmModal
        onSubmit={confirmModalData.onSubmit}
        text={confirmModalData.text}
        open={isConfirmModalOpen}
        onClose={handleCloseConfirmModal}
      />
    </>
  );
}
