import { useState } from 'react';
import Button from '@/components/molecules/Button';
import Spinner from '@/components/atoms/Spinner';
import TextArea from '@/components/molecules/TextArea';
import Modal from '@/components/molecules/Modal';
import Markdown from 'markdown-to-jsx';
import useAuthenticatedAxios from '@/hooks/useAuthenticatedAxios';
import { StatDisplay } from '@/components/molecules/War';
import { ArrowDown, ArrowUp } from 'lucide-react';

type GenerateAIInsightsModalProps = {
	warTeamId: number;
	children: React.ReactNode;
};

export default function GenerateAIInsightsModal({ warTeamId, children }: GenerateAIInsightsModalProps) {
	const axios = useAuthenticatedAxios();

	const [isLoading, setIsLoading] = useState(false);
	const [insights, setInsights] = useState<{ text: string; usage: { completionTokens: number, promptTokens: number } } | null>(null);
	const [observations, setObservations] = useState('');
	const [isOpen, setIsOpen] = useState(false);

	async function handleGenerateInsights() {
		setIsLoading(true);
		try {
			const response = await axios.put<{ text: string; usage: { completionTokens: number, promptTokens: number } }>(`/war/teams/${warTeamId}/insights`, {
				observations
			});
			setInsights(response.data);
		} catch (error) {
			console.error('Failed to generate AI insights', error);
		} finally {
			setIsLoading(false);
		}
	}

	function handleClose() {
		setIsOpen(false);
	}

	return (
		<>
			<div onClick={() => setIsOpen(true)}>
				{children}
			</div>
			<Modal className="max-w-[1000px] overflow-y-scroll max-h-[95vh]" open={isOpen} title="Gerar Insights com IA" onClose={handleClose}>
				{isLoading && <Spinner size="large" color="indigo" />}
				{insights && (
					<div className='mb-4'>
						<div className="flex gap-4 items-center mb-4">
							<StatDisplay text="Completion Tokens" number={insights.usage.completionTokens} Icon={ArrowDown} />
							<StatDisplay text="Prompt Tokens" number={insights.usage.promptTokens} Icon={ArrowUp} />
						</div>
						<Markdown className="text-md prose-invert prose prose-slate">
							{insights.text}
						</Markdown>
					</div>
				)}
				<TextArea
					label="Observações"
					value={observations}
					onChange={(e) => setObservations(e.target.value)}
					placeholder="O Thornwood é o melhor personagem do jogo"
				/>
				<div className="flex justify-between mt-8">
					<Button onClick={handleClose} color='secondary' disabled={isLoading}>
						Fechar
					</Button>
					<Button onClick={handleGenerateInsights} disabled={isLoading}>
						Gerar Insights
					</Button>
				</div>
			</Modal>
		</>
	);
}
